<script setup>
import {useBaseStore} from "~/stores/base";
import {
  PATH_BRANDS,
  PATH_COLLECTION_ALL_STYLES,
  PATH_HOME,
  URL_APP_DOWNLOAD,
  URL_BNTO_INSTAGRAM,
  URL_BNTO_LINKEDIN,
  URL_BNTO_TIKTOK
} from "~/utils/constants";

/** props **/
const props = defineProps({})

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const about_us = computed(() => ({
  title: "ABOUT US", // useI18n().t("footerAboutUs")
  links: [
    {
      label: "Our Story",
      value: useBaseStore().appInit.agreementLink.ourStoryLink
    },
    {
      label: "Buy New From VIAVIA",
      value: useBaseStore().appInit.agreementLink.buyFromViaViaLink
    },
  ],
}))
const help = computed(() => ({
  title: "HELP",
  links: [
    {
      label: "FAQ + Support",
      value: useBaseStore().appInit.agreementLink.faqSupportLink
    },
    {
      label: "Contact us",
      value: useBaseStore().appInit.agreementLink.contactUsLink
    },
    {
      label: "How it works",
      value: useBaseStore().appInit.agreementLink.howItWorksLink
    },
    {
      label: "Size Charts",
      value: "size-charts"
    },
  ],
}))
const explore = computed(() => ({
  title: "EXPLORE",
  links: [
    {
      label: "Explore All Products",
      value: PATH_COLLECTION_ALL_STYLES
    },
    {
      label: "Brands",
      value: PATH_BRANDS
    },
  ],
}))
const legal = computed(() => ({
  title: "LEGAL",
  links: [
    {
      label: "Privacy Policy",
      value: useBaseStore().appInit.agreementLink.privacyPolicyLink
    },
    {
      label: "Terms + Conditions",
      value: useBaseStore().appInit.agreementLink.termsConditionsLink
    },
  ],
}))
</script>

<template>
  <div class="home-footer-mobile" v-if="isMobile">
    <div class="top">
      <nuxt-link :to="PATH_HOME" class="top-left">
        <img src="@/assets/img/logo-black.png" alt="logo">
      </nuxt-link>
      <nuxt-link :to="URL_APP_DOWNLOAD" class="top-right">
        <img src="@/assets/img/icon-download.png" alt="download">
      </nuxt-link>
    </div>
    <div class="bottom">
      <div class="block">
        <div class="title">{{ about_us.title }}</div>
        <nuxt-link :to="item.value" class="link" v-for="(item) in about_us.links">{{ item.label }}</nuxt-link>
      </div>
      <div class="block">
        <div class="title">{{ help.title }}</div>
        <template v-for="(item) in help.links">
          <div v-if="item.value === 'size-charts'" class="link" @click="() => {useBaseStore().updateIsShowModalSizeCharts(true)}">{{ item.label }}</div>
          <nuxt-link v-else :to="item.value" class="link">{{ item.label }}</nuxt-link>
        </template>
      </div>
      <div class="block">
        <div class="title">{{ explore.title }}</div>
        <nuxt-link :to="item.value" class="link" v-for="(item) in explore.links">{{ item.label }}</nuxt-link>
      </div>
      <div class="block">
        <div class="title">{{ legal.title }}</div>
        <nuxt-link :to="item.value" class="link" v-for="(item) in legal.links">{{ item.label }}</nuxt-link>
      </div>
      <div class="block">
        <div class="title">{{ "STAY CONNECTED" }}</div>
        <div class="social-media">
          <nuxt-link :to="URL_BNTO_INSTAGRAM">
            <img src="@/assets/img/icon-ins.svg" alt="ins">
          </nuxt-link>
<!--          <nuxt-link :to="URL_BNTO_LINKEDIN">-->
<!--            <img src="@/assets/img/icon-linkedIn.svg" alt="linkedIn">-->
<!--          </nuxt-link>-->
          <nuxt-link :to="URL_BNTO_TIKTOK">
            <img src="@/assets/img/icon-tiktok.svg" alt="tiktok">
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
  <div class="home-footer-desktop" v-else>
    <div class="top">
      <nuxt-link :to="PATH_HOME" class="top-left">
        <img src="@/assets/img/logo-black.png" alt="logo">
      </nuxt-link>
      <nuxt-link :to="URL_APP_DOWNLOAD" class="top-right">
        <img src="@/assets/img/icon-download.png" alt="download">
      </nuxt-link>
    </div>
    <div class="bottom">
      <div class="block">
        <div class="title">{{ about_us.title }}</div>
        <nuxt-link :to="item.value" class="link" v-for="(item) in about_us.links">{{ item.label }}</nuxt-link>
      </div>
      <div class="block">
        <div class="title">{{ help.title }}</div>
        <template v-for="(item) in help.links">
          <div v-if="item.value === 'size-charts'" class="link" @click="() => {useBaseStore().updateIsShowModalSizeCharts(true)}">{{ item.label }}</div>
          <nuxt-link v-else :to="item.value" class="link">{{ item.label }}</nuxt-link>
        </template>
      </div>
      <div class="block">
        <div class="title">{{ explore.title }}</div>
        <nuxt-link :to="item.value" class="link" v-for="(item) in explore.links">{{ item.label }}</nuxt-link>
      </div>
      <div class="block">
        <div class="title">{{ legal.title }}</div>
        <nuxt-link :to="item.value" class="link" v-for="(item) in legal.links">{{ item.label }}</nuxt-link>
      </div>
      <div class="block">
        <div class="title">{{ "STAY CONNECTED" }}</div>
        <div class="social-media">
          <nuxt-link :to="URL_BNTO_INSTAGRAM">
            <img src="@/assets/img/icon-ins.svg" alt="ins">
          </nuxt-link>
<!--          <nuxt-link :to="URL_BNTO_LINKEDIN">-->
<!--            <img src="@/assets/img/icon-linkedIn.svg" alt="linkedIn">-->
<!--          </nuxt-link>-->
          <nuxt-link :to="URL_BNTO_TIKTOK">
            <img src="@/assets/img/icon-tiktok.svg" alt="tiktok">
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.home-footer-mobile {
  width: 100%;
  padding: 40px 16px 80px 16px;
  background-color: $color-BNTO-beige-light;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-left {
      display: flex;

      img {
        width: 118px;
        height: 40px;
      }
    }

    .top-right {
      display: flex;
      cursor: pointer;

      img {
        width: 136px;
        height: 40px;
      }
    }
  }

  .bottom {
    margin: 44px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .block {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;

      .title {
        //styleName: Eyebrow/small;
        font-family: "TWK Lausanne";
        font-weight: 650;
        font-size: 10px;
        line-height: 12px;
        text-align: left;

        color: $color-gray-400;
        margin-bottom: 5px;
      }

      .link {
        font-family: TWK Lausanne;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        text-align: center;

        cursor: pointer;
        margin-bottom: 5px;
      }

      .social-media {
        display: flex;
        justify-content: center;
        gap: 16px;
        margin-top: 27px;

        img {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}

.home-footer-desktop {
  width: 100%;
  min-width: $content-1280-width;
  background-color: $color-BNTO-beige-light;
  padding: 40px 0;

  .top {
    margin: 0 auto;
    width: $content-1280-width;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-left {
      display: flex;

      img {
        width: 70px;
        height: 24px;
      }
    }

    .top-right {
      display: flex;
      cursor: pointer;

      img {
        width: 180px;
        height: 52px;
      }
    }
  }

  .bottom {
    margin: 44px auto 0 auto;
    width: $content-1280-width;
    display: flex;
    justify-content: space-between;

    .block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        //styleName: Eyebrow/small;
        font-family: "TWK Lausanne";
        font-weight: 650;
        font-size: 10px;
        line-height: 12px;
        text-align: left;

        color: $color-gray-400;
        margin-bottom: 10px;
      }

      .link {
        font-family: TWK Lausanne;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        text-align: center;

        cursor: pointer;
        margin-bottom: 10px;
      }

      .social-media {
        align-self: center;
        display: flex;
        gap: 16px;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
</style>
